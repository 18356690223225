<template>
    <div>
        <DataTable class="mt-5" stripedRows :scrollable="true" scrollHeight="600px" :value="transformedData"
            responsiveLayout="scroll">
            <Column field="previousLocation" header="Location 'FROM'"></Column>
            <Column field="currentLocation" header="Location 'TO'"></Column>
            <Column field="start_at" header="Date Sent">
                <template #body="slotProps">
                    <span>
                        {{ formatStartAt(slotProps.data.start_at) }}
                    </span>
                </template>
            </Column>
            <Column field="created_by" header="User"></Column>
            <template #empty>
                No records found.
            </template>
        </DataTable>
        <!-- {{ transformedData }} -->
    </div>
</template>
<script>
export default {
    props: {
        loanEquipmentHistory: {
            type: Array,
            default: []
        }
    },
    computed: {
        transformedData() {
            const history = this.loanEquipmentHistory;//.reverse(); //.sort((a, b) => a.start_at - b.start_at);

            const formattedData = [];
            if (history.length == 1) {
                formattedData.push({
                    currentLocation: this.parseLocation(history[0]),
                    previousLocation: "",
                    start_at: history[0].start_at,
                    created_by: history[0].created_by
                })
            } else {
                for (let i = 1; i < history.length; i++) {
                    const currentLocation = this.parseLocation(history[i]);
                    const previousLocation = this.parseLocation(history[i - 1]);
                    const start_at = history[i].start_at;
                    const created_by = history[i].created_by;

                    formattedData.push({
                        currentLocation: currentLocation,
                        previousLocation: previousLocation,
                        start_at: start_at,
                        created_by: created_by
                    });
                }
            }

            return formattedData.reverse();
        }
    },
    methods: {
        formatStartAt(start_at) {
            return start_at == 0 || start_at == null ? "" : this.$moment.unix(start_at).format("DD-MM-YYYY");
        },
        parseLocation(item) {
            return item.status === "AT CUSTOMER" ? item.current_location.name : item.status;
        }
    }
}
</script>